<template>
  <div class="d-flex flex-column justify-center align-center white fill-height">
    <div>
      <v-app-bar
        absolute
        app
        color="primary"
        flat
        class="d-flex align-center justify-center"
        height="110"
      >
        <a href="#" class="text-center pa-5">
          <img
            src="/media/logos/logo_light_155x30.png"
            class="max-h-70px"
            alt
          />
        </a>
      </v-app-bar>
    </div>
    <div
      class="
        py-6
        pt-15
        fill-height
        flex-grow-1
        d-flex
        flex-column flex-root
        justify-center
        align-center
      "
    >
      <vue-inline-alert
        v-if="errorMessage"
        :message="errorMessage"
        message-type="error"
        no-close-btn
      ></vue-inline-alert>
      <v-row v-if="errorMessage">
        <v-col>
          <v-btn
            color="primary"
            class="text-capitalize"
            @click="$router.push({ name: 'dashboard' }).catch()"
            >Go Home</v-btn
          >
        </v-col>
      </v-row>
      <template v-if="!errorMessage">
        <h3 class="bold-text activate-title">
          Activating your account, please wait...
        </h3>
        <span class="text-muted bold-text font-size-h4 font-size-h3-lg mt-2">
          You will be automatically redirected, if not please click
          <router-link
            class="text-primary font-weight-bolder"
            :to="{ name: 'signin' }"
            >here</router-link
          >
        </span>
      </template>
    </div>
  </div>
</template>

<script>
import { VERIFY_ACTIVATE, ACTIVATE_ACCOUNT } from "@/store/users/auth.module";

export default {
  components: {},
  data() {
    return {
      errorMessage: "",
    };
  },
  mounted() {
    this.errorMessage = "";
    this.$store
      .dispatch(VERIFY_ACTIVATE, this.$route.params.token)
      .then(async ({ token }) => {
        try {
          await this.$store.dispatch(ACTIVATE_ACCOUNT, token);
        } catch (e) {
          this.notifyErrorMessage(e.message || "Something went wrong.");
        }
        this.$router.push({
          name: "start",
          params: { message: "Your account successfully activated." },
        });
      })
      .catch((error) => {
        this.$router.push({
          name: "error",
          params: {
            message: error.message,
          },
        });
      });
  },
};
</script>
